import Vue from "vue";
import VueI18n from "vue-i18n";
import zhCN from '@/assets/i18n/zh-CN';
import enUS from '@/assets/i18n/en-US';

import ElementUI from 'element-ui';
import uiZhLocale from 'element-ui/lib/locale/lang/zh-CN';
import uiEnLocale from 'element-ui/lib/locale/lang/en';

Vue.use(VueI18n);
Vue.locale = () =>{}

let local = localStorage.getItem('locale');

if (!local){
    local = navigator.language;
    if(local.indexOf('zh') > -1) {
        local = 'zh-CN';
    }else if (local.indexOf('en') > -1){
        local = 'en-US';
    }
}
const messages = {
    'zh-CN': {...zhCN, ...uiZhLocale},
    'en-US': {...enUS, ...uiEnLocale},
}

export const i18n = new VueI18n({
    locale: local,
    fallbackLocale: 'zh-CN',
    messages,
});

Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
});

export function setLocale (lang) {
    i18n.locale = lang;
    localStorage.setItem('locale', lang);
    location.reload(true);
    // document.querySelector('html').setAttribute('lang', lang);
    // return lang;
}

export const languageOptions = [
    {key: 'zh-CN', name: '简体中文'},
    {key: 'en-US', name: 'English'},
];


