<template>
    <div class="flex flex-align-center">
        <svg-icon name="ib-locale" className="fonts-16"></svg-icon>
        <div class="flex margin-l-5">
            <template v-for="(item, index) in langList">
                <div class="fonts-14" :class="{ 'text-weight-600': item.key === lang.key, 'cursor-pointer': item.key !== lang.key }" :key="`lang-${index}`" @click="changeLanguageEvent(item)">{{ item.name }}</div>
                <el-divider direction="vertical" v-if="index !== langList.length - 1" :key="`divider-${index}`"></el-divider>
            </template>
        </div>
    </div>
</template>
<script>
import {i18n, setLocale, languageOptions} from '@/assets/i18n';
export default {
    data() {
        return {
            lang: {key: 'zh-CN', name: '简体中文'},
            langList: languageOptions,
        }
    },
    created() {
        let locale = i18n.locale;
        this.lang = this.langList.find(item => item.key === locale);
    },
    methods: {
        changeLanguageEvent(item){
            this.lang = item;
            setLocale(item.key);
        },
    }
}
</script>
