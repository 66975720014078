<template>
    <el-dropdown @command="handleLanguageCommand">
        <div class="change-language-btn flex flex-align-center cursor-pointer color-222222">
            <svg-icon name="ib-locale" className="font-weight: bold;"></svg-icon>
            <!-- <div class="margin-l-5 fonts-12">{{ lang.name }}</div> -->
        </div>
        <el-dropdown-menu slot="dropdown">
            <template v-for="(item, index) in langList">
                <el-dropdown-item class="fonts-14" :command="item.key" :key="index" :disabled="item.key === lang.key">{{ item.name }}</el-dropdown-item>
            </template>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
import {i18n, setLocale, languageOptions} from '@/assets/i18n';
export default {
    data() {
        return {
            lang: {key: 'zh-CN', name: '简体中文'},
            langList: languageOptions,
        }
    },
    created() {
        let locale = i18n.locale;
        this.lang = this.langList.find(item => item.key === locale);
    },
    methods: {
        handleLanguageCommand(command) {
            this.lang = this.langList.find(item => item.key === command);
            setLocale(command);
        }
    }
}
</script>
<style lang="scss" scoped>
.change-language-btn{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
        background-color: #EDEDED;
    }
}
</style>
