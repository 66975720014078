const commonApi = {
    /**
     * type = mt-domains-tags 引擎标签
     */
    queryDictData: '/api/system/sys/dict_data', //get 查询字典数据 ?type=xx
    queryNavigationList: '/api/system/workspace/navigation', //get 标签栏通知列表

    /**下载弹窗 */
    queryDownloadTaskList: '/api/system/download-resource/visible/list', //get 下载资源列表
    getDownloadUrl: '/api/system/download-resource/download-url/get', //get 获取资源下载地址
    deleteDownloadItem: '/api/system/download-resource/delete', //post 删除资源

    /**站内信消息 */
    queyUnReadMessageCount: '/api/system/notify-message/get-unread-count', //get 站内信-未读数量
    queryMessageList: '/api/system/notify-message/my-page', //post 获取我的站内信列表
    deleteMessage: '/api/system/notify-message/delete', //get 站内信-删除
    triggerMessageAction: '/api/system/notify-message/update-action', //get 站内信-行为操作
    setMessageRead: '/api/system/notify-message/update-read', //get 站内信-标识已读
    setMessageReadAll: '/api/system/notify-message/update-all-read', //get 站内信-标识全部已读
    queryAlertMessageList: '/api/system/notify-message/pop-up', //get 消息弹出提醒

    queryTeamFilterOptions: '/api/system/team/filter-item', //get 筛选项:团队列表

    queryCheckAndSwitchInfo: '/api/system/users/system-setting/check-switch', //post TMS验证切换入口

    queryLanguageDict: '/api/system/cfg/langs/mapping_sort', //get 语种映射数据

    //
    queryPurchaseOptions: '/api/system/payment/purchaseOptions', //get 套餐类型查询
    queryApplyFormSelectOptions: '/api/system/organization/apply/options', //get 旗舰版申请下拉选项查询

    batchCreateDownloadTask: '/api/system/download-resource/batch-create', //post 批量创建下载任务


};

const wsApi = {
    imWebsocket: '/im/ws', //websocket
};

const projectApi = {
    uploadSingleFile:'/api/cat/documents', // post 上传文件 - 单个文档
    uploadMultipleFile:'/api/cat/documents/files', //post 上传文件 - 多个文档
    queryUploadToken:'/api/cat/documents/upload/token', //get 文件上传-获取签名

    startTranslation:'/api/cat/projects/translation', // post 开始翻译
    queryAllEnginesList:'/api/translator/engines', //get 引擎：全部信息
    queryEnginesByLang:'/api/translator/engines/lang_pair', //get 引擎：根据语言对获取配置
    queryProjectList:'/api/cat/projects/my', //get 项目列表查询（我的项目）
    pollingProjectStatus: '/api/cat/projects/preprocess_status', //post 获取项目预处理阶段状态
    downloadProjectFiles:'/api/cat/projects/download', //get 项目文档下载
    batchDownloadProjectFiles:'/api/cat/projects/batch-export', //get 项目文档下载
    deleteProjects:'/api/cat/projects/batch-delete', // post 项目删除
    pollingCustomEngineStatus:'/api/system/workspace/engine/models/loaded', //get 语言模型是否已经加载
    retryTask: '/api/cat/projects/{id}/retry_trans', //post 重译：项目级别
    queryProjectSetting: '/api/cat/projects/setting', //get 获取配置信息
    updateProjectsBasicInfo: '/api/cat/projects/basic', //put 修改基本信息
    updateProjectsSettingInfo: '/api/cat/projects/{id}/setting', //put 修改配置信息
    queryReferenceEngineList: '/api/translator/engines/reference_list', //post 引擎：我的参考引擎列表
    queryEnginesAndRecommendByLang: '/api/translator/engines/recommend', //get 引擎：语向推荐引擎
    changeDocumentProcessMode: '/api/cat/translation-plan/change-document-process-mode', //post 切换文档翻译流程

    // 任务部分
    queryMyTaskList: '/api/cat/translation-plan/assigned-to-me', //post 我的任务列表
    queryMyTaskStatisticsList: '/api/cat/translation-plan/my-task-statistics', //get 我的任务统计
    
    queryAssignedList: '/api/system/team/member/listOfAssigned', //get 分配译员列表
    taskAssign: '/api/cat/project/translation-task/assign', //post 单一任务分配
    taskReassign: '/api/cat/project/task/reassign', //post 单一任务重新分配
    queryDocAssignList: '/api/cat/project/translation-task/get', //get 项目流程列表 ?documentId=?
    queryDocTaskDetail: '/api/cat/project/task/get', //post
    queryDocPreviousTaskDetail: '/api/cat/project/translation-task/previous', //get 当前文档上一个任务详情 ?documentId=?
    submitReject: '/api/cat/project/translation-task/previous/returnTo', //post 退回
    submitRejectTo: '/api/cat/translation-plan/rollback-to-specified', //post 任务回退操作
    submitRejectToPrev: '/api/cat/translation-plan/rollback-to-previous', //post 任务回退到上一步操作


    queryWorkflowList: '/api/cat/projects/translation/process', //get 项目流程
    queryTaskTypeList: '/api/cat/translation-plan/filter-item', //get 任务类型筛选

    queryTaskHistory: '/api/cat/translation-plan/operation-note', //get 翻译流程历史

    acceptOrRefuseTask: '/api/cat/translation-plan/batch-accept', //post 批量接受或拒绝任务
    queryTaskPlanList: '/api/cat/translation-plan/project-preview', //get 翻译译任务规划列表

    queryProjectInitData: '/api/cat/projects/init_data', //get 获取项目初始化数据

    checkAndAcceptProject: '/api/cat/project/custom/checkAndAccept', //post 项目验收

    bathSubmitTask: '/api/cat/translation-plan/batch/one_click_submission',

    // 文件分析
    queryDocAnalysis: '/api/cat/projects/analysis/query', //get 文档分析列表
    downloadDocAnalysis: '/api/cat/projects/analysis/download', //get 文档分析下载

    saveProjectMemPenaltyPoints: '/api/cat/projects/setPenaltyPoints', //post 保存项目记忆库罚分
    queryTaskStatisticsList: '/api/cat/projects/task-statistics', //get 翻译任务统计

    queryAllProjectTaskStatisticsFilterOptions: '/api/cat/projects/task-statistics-options', //get 项目任务统计选项

    importTaskAssignmentFile: '/api/cat/translation-plan/import/assignment', //post 任务离线导入

    creataTemplateFromProject: '/api/cat/projects/templates/form-project', //post 从项目创建模版
    queryTemplateListPage: '/api/cat/projects/templates/page', //get 模版列表
    editTemplate: '/api/cat/projects/templates/detail', //put 修改模版
    deleteTemplate: '/api/cat/projects/templates/delete', //post 删除模版
    queryTemplateOptions: '/api/cat/projects/templates/list-basic', //get 模版选项
    queryTemplateDetail: '/api/cat/projects/templates/detail', //get 模版详情
    

};

const docApi = {
    getDocLanguage: '/api/cat/documents/lang_detect', //识别文档原语言方向
    getTextLanguage: '/api/translator/translation/detection', //post 语言识别：文本
    queryRandomParagraphList:'/api/translator/translation/random_text', // get 随机获取文档段落
    translationRandomDoc:'/api/translator/translation/doc', // post 文本翻译 - 新建项目
    translationRandomText:'/api/translator/translation/text', // post 文本翻译 - 引擎管理
    translationSentence: '/api/cat/editor/machine_translation', //post 文本翻译 - 编辑器
    referenceTranslationSentence: '/api/cat/editor/reference_translation', //post 文本翻译 - 编辑器
    polishText: '/api/translator/polishing', //post 文本润色
    translationRandomDocForAggregation:'/api/translator/translation/aggregation', //post 聚合翻译 - 根据领域获取所有引擎结果
    queryDocumentsList:'/api/cat/projects/documents', //get 项目文档列表 
    pollingDocumentsStatus: '/api/cat/documents/preprocess_status', //post 轮询文档预处理状态
    retranslateDoc:'/api/cat/documents/retry_trans', //post 重译：文档级别
    deleteDocument:'/api/cat/documents/batch-delete', //post 批量删除文档
    queryDocumentSentences:'/api/cat/editor/sentence/page', //get 文档 - 句对信息
    saveSentences:'/api/cat/editor/sentence/save', //put 保存 - 句子单条更新
    saveDocuments:'/api/cat/documents/{id}', //put 保存 - 基于文档更新
    querySentencesParticiple:'/api/cat/sentences/tokenizer', //post 句子分词
    queryTranslationExcludeSelected:'/api/translator/translation/editor', //post 聚合翻译 - 随机引擎结果（除用户选择外）
    downloadDocumentFile:'/api/cat/documents/download', //get 下载文件
    queryDocumentPreview:'/api/cat/editor/preview', //get 文档 - 预览 html ?doc_id=&type=html
    queryInteractionTranslation:'/api/translator/translation/interaction', //post 翻译 - 交互式翻译 Async
    querySentenceEditTrace:'/api/cat/editor/trace/sentence-edit-trace', //get 获取句子编辑痕迹
    queryDocumentEditTrace:'/api/cat/editor/trace/document-edit-trace', //get 获取文档编辑痕迹

    cancelLastStep:'/api/cat/editor/cancel', //post 撤销一步
    recoverStep:'/api/cat/editor/recover', //post 恢复一步

    setSentencesLock:'/api/cat/editor/translatable', //post 句子：翻译锁定

    splittingSentences:'/api/cat/editor/splitting', //post 句子：拆分
    mergingSentences:'/api/cat/editor/merging', //post 句子：合并

    queryNotConfirmSent:'/api/cat/editor/sentences/non_confirms/first', //post 句子：返回第一个未确认句子
    appendProjectFiles: '/api/cat/projects/document/append', //post 追加文档
    querySentencesTagsQA: '/api/cat/editor/tags/qa', //post 添加 QA 标签
    saveSourceSentences:'/api/cat/editor/sentences/source', //put 更新 - 单条原文
    getSentencesPageNumber: '/api/cat/editor/sentence/location', //post 语句定位（所在页）
    queryMemorySegments: '/api/cat/editor/mems/segments', //post 记忆库片段搜索
    queryTermSegments: '/api/cat/editor/terms/segments', //post 术语库片段搜索
    downloadQaReport:'/api/cat/editor/qa_report/download', //下载QA报告

    downloadStyleGuideFiles: '/api/cat/projects/download/style_guide_docs', //post 风格指南：创建下载任务
    checkStyleGuideFiles: '/api/cat/projects/style-guide/check-before-download', //get 风格指南下载前检查

    /**评论接口 */
    queryCommentList: '/api/cat/document/comment/list', //get 语句评论列表,
    createComment: '/api/cat/document/comment/create', //post 添加评论,
    deleteComment: '/api/cat/document/comment/delete', //get 删除评论
    replyComment: '/api/cat/document/comment/reply', // post 回复文档评论

    /**编辑器模式 */
    setEditorMode: '/api/cat/editor/interactive_mode/change', //post 切换交互模式

    fillTags: '/api/cat/editor/docs/{id}/tags', //post 自动填充 Tag
    fillSentenceTags: '/api/cat/editor/tags/target', //post 复制标签
    quickSubmit: '/api/cat/editor/one_click_completion', //post 一键填充并确认

    checkTaskStatus: '/api/cat/project/translation-task/submit-check', //get 提交-检查
    setTaskStatus: '/api/cat/translation-plan/submit', //post 任务手动提交

    /**任务 */
    // queryFileTaskList: '/api/cat/project/translation-task/listByDocumentId', //get 获取文档任务列表
    queryFileTaskList: '/api/cat/translation-plan/document-preview', //get 获取文档任务列表
    queryAssignTableFilterOptions: '/api/system/team/member/assign/options', //get 分配任务用户列表表头筛选项
    queryAssignTableSubFilterOptions: '/api/system/team/member/assign/options/sub/{type}',
    queryAssignTableList: '/api/system/team/member/assign/list', //get 分配任务查询译员列表
    // batchAssignTask: '/api/cat/project/translation-task/batch-assign', //post 批量任务分配
    batchSetAssignmentTask: '/api/cat/translation-plan/batch-assign', //post 批量分配
    batchSetDeadline: '/api/cat/project/translation-task/batch-reset-deadline', //post 批量设置截至日期
    batchSetAssignmentDeadline: '/api/cat/translation-plan/batch-reset-deadline', // post 批量重置截止时间

    submitSplitDoc: '/api/cat/translation-plan/split', //post 拆分文档

    queryEditorPreviewUrl: '/api/cat/editor/preview-url', //get 获取文档预览地址

    getMTRating: '/api/cat/editor/mt/rating', //get 获取机器翻译评分

};
const userApi = {
    login:'/api/system/auth/login', // post 登录接口 
    logout:'/api/system/auth/logout', // delete 退出登录 
    setUser:'/api/system/users', //post 新增用户，// put 修改用户
    offlineUserLogin:'/api/system/auth/online', // delete 踢出用户 
    setPassword:'/api/system/users/password', // post 修改密码
    //注册接口
    sendCaptcha: '/api/system/auth/captcha', //post 发送验证码
    submitRegister: '/api/system/auth/register', //post 注册
    submitForgetPwd: '/api/system/auth/reset_pwd', //忘记密码：修改密码

    sendCaptchaAuth: '/api/system/users/captcha', //post 发送验证码 - 需认证
    settingAccount: '/api/system/accounts/settings', //post 账号管理

    getWechatQrcode: '/api/system/wechat/qrcode', //get 获取二维码
    pollingWechatLoginStatus: '/api/system/wechat/scanCheck', //get 二维码扫码校验【登录】
    pollingWechatQRStatus: '/api/system/wechat/bindCheck', //get 二维码扫码校验【账号管理绑定】
    bindWechatAndPhone: '/api/system/wechat/wx_phone/bind', //post 绑定微信和手机号
    unbindWechat: '/api/system/wechat/unbind', //get 解绑微信公众号
    clearFirstWindow: '/api/system/users/windows', //post 关闭弹窗

    //账号，会员相关
    queryUserInfo:'/api/system/accounts/profile', // get 个人信息
    queryAccountInfo: '/api/system/accounts/getAccountInfo', //get 获取账户信息(替代个人信息查询接口)
    queryOrderList:'/api/system/workspace/orders', //get 我的订单
    getProTrial: '/api/system/workspace/pro_trial', //post Pro 账号试用
    queryInviteList: '/api/system/workspace/invite/list', //get 邀请好友列表
    queryTrafficList: '/api/system/traffic/packs', //get 字符流量：分页数据

    //权限相关
    queryPermissionList: '/api/system/systemManagement/role/getMenuInfo', //get 获取用户权限信息
    
};
const payApi = {
    queryPriceOptions: '/api/system/payment/priceOptions', //get 价格选项查询
    queryErpExpiredData: '/api/system/payment/calculateExpiredDate', // post 计算企业版服务到期时间
    createOrder: '/api/system/payment/order', // post 创建订单
    queryOrder: '/api/system/payment/query', // get 查询订单
    generatingContract:'/api/system/payment/signContract', //post 签署企业版合同
    queryManageOrg: '/api/system/workspace/getManagedOrg', //get 获取本人管理的企业
};
const jargonApi = {
    queryJargonList:'/api/cat/terms', //get 查询术语信息 - 项目 Id、原文或译文 Id ?project_id=110&text=原
    downloadTemplate:'/api/cat/terms/download/template', //get 下载模板
    batchImport:'/api/cat/terms/batch_import', //post 批量导入数据
    batchDelete:'/api/cat/terms/batch_delete', //delete 删除多条术语
    setJargon:'/api/cat/terms', //post 新增术语,put 修改术语
    matchJargon:'/api/cat/editor/terms/source', //post 匹配术语 - 原文中匹配术语对信息
    reTranslate:'/api/cat/editor/term/retrans', //post 重译 - 添加术语后，重新翻译文档中匹配的句对
    
};
const termApi = {
    termDB:'/api/cat/terms', //get 查询术语库信息 ?pageNumber=1&pageSize=10&sourceLang=en&targetLang=zh&name=lab  post 新增术语库
    queryTermDBList:'/api/cat/terms/queryList', //post 查找项目相关术语库
    deleteTermBatch:'/api/cat/terms/batch_delete', //post 批量删除术语库
    mergeTerm:'/api/cat/terms/merge', //post 合并术语库
    uploadTermFile:'/api/cat/terms/new/file', //post 上传术语库文件 - 新建术语库
    appendTermFile: '/api/cat/terms/batch_import_append', //post 批量导入数据
    downloadFile:'/api/cat/terms/{id}/download', //get 下载文件 ?type=excel|tbx
    termsDetail:'/api/cat/terms/{id}/detail', //get 查询术语库详情 - 术语库 Id ?pageNumber=1&pageSize=10  post 新增术语 - 术语库 Id
    termsDetailLocation:'/api/cat/terms/{id}/detail/{dId}', //get 查询术语库详情 - 术语库 Id ?pageNumber=1&pageSize=10  post 新增术语 - 术语库 Id
    submitAddTerm: '/api/cat/terms/detail/add', //post 保存术语
    saveEditTerm:'/api/cat/terms/detail', //put 修改术语
    batchDeleteTerm:'/api/cat/terms/detail/batch_delete', //post 批量删除多条术语

    customTermsDetail:'/api/cat/editor/terms/custom-list', //post 术语定制-列表查询
    appendCustomTermFile: '/api/cat/editor/terms/batch_import_append', //post 批量导入数据
    saveAddCustomTerm:'/api/cat/editor/terms/custom', //post 保存术语 - 术语定制
    // saveAddCustomTermBatch:'/api/cat/terms/custom_batch', //post 术语定制：批量保存

    createTermExtractionTask:'/api/cat/terms/extraction/task', //post 术语抽取：添加任务
    queryTermExtractionStatus:'/api/cat/terms/extraction/status', //get 术语抽取：轮询状态 ?taskId=5fe46c94de974cd58021522b99f2d601
    queryTermExtractionList:'/api/cat/terms/extraction/list', //get 术语抽取：获取结果 ?taskId=5fe46c94de974cd58021522b99f2d601
    queryTermExtractionRetransList:'/api/cat/terms/extraction/retrans', //post 术语抽取：重译
    stopTermsExtraction:'/api/cat/terms/extraction/cancellation', //post 术语抽取：终止任务
    createTermMultiLanguage: '/api/cat/terms/batch_create', //post 批量创建术语库

    queryTermsByFulltext: '/api/cat/terms/aggQuery', //post 获取术语库列表

};  
const qaApi = {
    triggerQA:'/api/cat/editor/qa/v2/problem/list', //post 触发全文检查
    ignoreQAList: '/api/cat/editor/qa/v2/problem/ignored-list', //get 查询忽略列表 
    ignoreQAProblem: '/api/cat/editor/qa/v2/problem/ignore', //post 问题忽略
    cancelIgnoreQAProblem:'/api/cat/editor/qa/v2/problem/unignore', //post 取消忽略QA结果
    queryQaConfig:'/api/cat/projects/qa_setting/query', //post 查询QA配置项
    updateQaConfig:'/api/toolkit/qa/config/update', //修改QA配置项
};
const findReplaceApi = {
    findAll:'/api/cat/editor/findAll', //post 内容查找
    replaceAll:'/api/cat/editor/replaceAll', //post 全部替换
    replaceOne:'/api/cat/editor/replaceOne', //post 单项替换
};

const memoryApi = {
    memoryDB:'/api/cat/mems', //get 查询记忆库信息 ?pageNumber=0&pageSize=10&sourceLang=en
    deleteMemoryBatch:'/api/cat/mems/batch_delete', //post 批量删除记忆库
    mergeMemory:'/api/cat/mems/merge', //post 合并记忆库
    uploadMemoryFile:'/api/cat/mems/new/file', //post 上传记忆库文件 - 新建记忆库
    appendMemoryFile: '/api/cat/mems/batch_import_append', //post 批量导入数据
    downloadFile:'/api/cat/mems/{id}/download', //get 下载文件 ?type=excel|tbx
    downloadTemplate:'/api/cat/mems/download/template',//get 下载模板
    memoryDetail:'/api/cat/mems/{id}/detail', //get 查询记忆库详情 - 记忆库 Id ?pageNumber=1&pageSize=10  post 新增记忆 - 记忆库 Id
    memoryDetailLocation:'/api/cat/mems/{id}/detail/{dId}', //get 查询记忆库详情 - 记忆库 Id ?pageNumber=1&pageSize=10  post 新增记忆 - 记忆库 Id
    submitAddMemory:'/api/cat/mems/detail/add', //post 新增记忆
    saveEditMemory:'/api/cat/mems/detail', //put 修改记忆
    batchDeleteMemory:'/api/cat/mems/detail/batch_delete', //post 批量删除多条记忆
    batchImport:'/api/cat/mems/batch_import', //post
    queryMemoriesForSource:'/api/cat/editor/mems/source', //post 匹配记忆库 - 原文中匹配记忆库信息
    createMemoryMultiLanguage: '/api/cat/mems/batch_create', //post 批量创建记忆库

    queryMemorysByFulltext: '/api/cat/mems/aggQuery', //get 聚合查询记忆库
};

const toolsApi = {
    uploadFile:"/api/toolkit/common/upload", //post 文件上传接口
    // startTask:'/api/toolkit/common/task/start', //post 开始对齐
    exportResult:'/api/toolkit/common/result/export', //get 任务结果导出
    deleteTask:'/api/toolkit/common/task/delete', //post 删除对齐任务
    deleteResult:'/api/toolkit/common/result/delete', //post 删除对齐结果
    batchSaveResult:'/api/toolkit/et/result/batchUpdate', //post 批量更新结果
    updatePageSize: '/api/toolkit/common/pager/update', //post 更改分页大小
    //语料对齐
    queryAlignTasklist:'/api/toolkit/alignment/task/page', // get 对齐任务列表
    queryAlignResultList:'/api/toolkit/alignment/result/page', //get 对齐结果列表
    queryAlignSubtaskStatus: '/api/toolkit/alignment/subtask/query', //get 查询子任务
    awakeTask: '/api/toolkit/common/task/resume', //get 任务唤醒
    restartTask: '/api/toolkit/common/task/retry', //get 任务重试
    updateAlignResult:'/api/toolkit/alignment/result/update', //post 修改对齐结果
    createAlignResult:'/api/toolkit/alignment/result/create', //post 添加对齐结果
    insertAlignResult:'/api/toolkit/alignment/result/insert', //post 插入空白记录
    copyAlignResultToMemory:'/api/toolkit/alignment/task/c2m', //post 复制到记忆库
    splitResult:'/api/toolkit/alignment/result/split', //post 语句拆分
    deleteAlignResult:'/api/toolkit/alignment/result/delete', //post 对齐结果删除
    submitAlignPair:'/api/toolkit/alignment/result/pair', //post 手动对齐
    cleanUnAlignResult:'/api/toolkit/alignment/result/clean', //get 清除未对齐结果
    submitAlignDrag:'/api/toolkit/alignment/result/drag', //post 对齐结果拖拽
    mergeResult:'/api/toolkit/alignment/result/merge', //post 对齐结果合并
    confirmRowAlign:'/api/toolkit/alignment/result/confirm', //get 单行确认对齐
    executeRepeal:'/api/toolkit/alignment/result/revoke', //get 1.5 撤销
    executeRecover:'/api/toolkit/alignment/result/recover', //get 1.5 恢复
    startAlignTask: '/api/toolkit/alignment/task/start', //post 1.5 开始语料对齐任务
    cancelAlignHighlight: '/api/toolkit/alignment/result/highlight/cancel', //post 1.6取消结果高亮
    replaceAlignResult: '/api/toolkit/alignment/result/replace', //post 1.6 对齐结果内容替换
    clearFilterAlignResult: '/api/toolkit/alignment/result/sweep', //post 1.6 清除筛选结果
    addMark: '/api/toolkit/alignment/result/mark', //post 标记对齐结果
    removeMark: '/api/toolkit/alignment/result/unmark', //post 移除对齐结果标记
    saveAlignEditTitle: '/api/toolkit/alignment/task/rename', //post 任务重命名

    //规则对齐接口
    queryBdaAlignResultList: '/api/toolkit/bda/task/detail', //get 任务详情（对齐结果列表）规则
    submitBdaAlignDrag: '/api/toolkit/bda/result/drag', //post 对齐结果拖拽 规则
    updateBdaAlignResult: '/api/toolkit/bda/result/update', //post 修改对齐结果 规则
    insertBdaAlignResult: '/api/toolkit/bda/result/insert', //post 插入空白行 规则
    deleteBdaAlignResult: '/api/toolkit/bda/result/delete', //post 删除对齐结果 规则
    splitBdaResult: '/api/toolkit/bda/result/split', //post 对齐结果拆分 规则
    mergeBdaResult: '/api/toolkit/bda/result/merge', //post 对齐结果合并 规则
    executeBdaRepeal: '/api/toolkit/bda/result/revoke', //post 撤销 规则
    executeBdaRecover: '/api/toolkit/bda/result/recover', //post 恢复 规则
    replaceBdaAllAlignResult: '/api/toolkit/bda/result/replace/all', //post 全部替换 规则

    
    //QA
    queryQATasklist:'/api/toolkit/qa/task/page', //get QA任务列表
    queryQAResultlist:'/api/toolkit/qa/result/page', //get QA结果列表
    startQATask: '/api/toolkit/qa/task/start', //post 1.5 开始QA对齐任务

    queryQAResultTypes: '/api/toolkit/qa/v2/task/info', //get 获取任务详情及错误类型
    queryQAResultListByType: '/api/toolkit/qa/v2/problem/listByType', //get 根据类型获取错误详情列表
    setProblemMark: '/api/toolkit/qa/v2/problem/mark', //post 标记QA问题

    //术语提取
    queryETTasklist:'/api/toolkit/et/task/page', //get 术语提取任务列表
    queryETResultlist:'/api/toolkit/et/result/page', //get 术语提取结果列表
    updateETResult:'/api/toolkit/et/result/update', //post 修改术语提取结果
    createETResult:'/api/toolkit/et/result/create', //post 添加术语提取结果
    copyETResultToTerm:'/api/toolkit/et/result/c2t', //post 复制到术语库
    startETTask: '/api/toolkit/et/task/start', //post 1.5 开始术语提取对齐任务

    //机翻检测
    queryMTITaskList: '/api/toolkit/mti/task/page', //get 任务列表
    startMTITask:'/api/toolkit/mti/task/start', //post 开始任务
    queryMTISubtask: '/api/toolkit/mti/task/subtasks', //get 子任务列表
    queryMTISubtaskDetail: '/api/toolkit/mti/task/subtask/detail', //get 子任务详情
    retrySubtask: '/api/toolkit/mti/task/subtask/retry', //get 子任务重试
    queryMTIEngineList: '/api/toolkit/mti/engines', //get 引擎检测机翻列表

    //AI润色
    createTxtPolishTask: '/api/toolkit/polishing/task/txt', //post 创建任务
    createDocumentPolishTask: '/api/toolkit/polishing/task/doc', //post 创建文档润色任务
    pollingPolishResult: '/api/toolkit/polishing/task/{id}/result', //get 获取任务结果
    queryPolishModelList: '/api/toolkit/polishing/engines', //get 获取引擎列表
    stopDocumentPolishTask: '/api/toolkit/polishing/task/terminate', //post 终止任务
    restartDocumentPolishTask: '/api/toolkit/polishing/retry', //post 任务手动重试
    downloadDocumentPolishFile: '/api/toolkit/polishing/download', //post 下载文档润色结果
    queryFileHistoryList: '/api/toolkit/polishing/history', //get 文档润色历史
    deleteFilePolishTask: '/api/toolkit/polishing/history/delete/{id}', //post 文档润色历史删除
    
    //AI视频翻译
    startVideoTranslateTask: '/api/toolkit/vt/task/start', //post 开始任务
    queryVideoTranslateTasks: '/api/toolkit/vt/task/page', //get 任务列表
    deleteVideoTranslateTask: '/api/toolkit/vt/task/batch-delete', //post 删除任务
    queryVTLimitSize: '/api/toolkit/common/usage-limit/get', //get 获取当日可用次数
    downloadTranslatedVideo: '/api/toolkit/vt/task/getResultDownloadUrl', //获取视频下载链接
    queryVideoLanguageOptions: '/api/toolkit/vt/task/support-lang', //get 获取语言列表
    startVideoTranslateTasksStatus: '/api/toolkit/vt/task/check-status', //POST 检查任务状态
    queryVideoTaskInfo: '/api/toolkit/vt/task/get', //get 获取任务详情-taskId
    queryVideoSubtitlePage: '/api/toolkit/vt/taskDetail/page', //get 视频翻译任务文本列表-分页
    saveEditVideoSentence: '/api/toolkit/vt/taskDetail/save', //post 视频翻译任务-修正文本译文
    confirmVideoSentence: '/api/toolkit/vt/taskDetail/confirm', //get 视频翻译任务-确认译文

    //gpt文档翻译
    createGPTTranslateTask: '/api/toolkit/gt/task', //post 开始任务
    queryGPTTranslateTasks: '/api/toolkit/gt/task/list', //get 任务列表
    deleteGPTTranslateTask: '/api/toolkit/gt/task', //post 删除任务
    queryGPTTranslateLanguageOptions: '/api/system/cfg/langs/engine/mapping_sort', //根据引擎key获取支持语种
    queryGPTTranslateTaskStatus: '/api/toolkit/gt/task/status',
    pollingGPTTranslateTaskTokenInfo: '/api/toolkit/gt/task/tokenInfo',
    startGPTTranslateTask: '/api/toolkit/gt/task/start',


    queryGPTTranslateTasksDetail: '/api/toolkit/gt/task/detail' //get 任务文件列表

};

const myEngineApi = {
    uploadFile: '/api/cat/mt/corpus/file', //post 上传语料
    addCheckCorpusTask: '/api/cat/mt/corpus/preprocess/task', //post 预处理：添加语料验证任务
    queryData:'/api/cat/mt/models/data', //get 轮询接口：我在哪里？
    postCorpusCleaning: '/api/cat/mt/corpus/cleaning', //post 审核：清洗语料
    postCorpusReCleaning: '/api/cat/mt/corpus/recleaning', //post 审核：重新清洗语料
    submitToReview: '/api/cat/mt/trains/man_review', //post 审核：人工审核
    getTestSet: '/api/cat/mt/models/{id}/test_sets', //post 模型：获取测试集 {id}=模型id
    updateEngineName: '/api/cat/mt/engines', //put 引擎：更新基本信息
    deleteEngine: '/api/cat/mt/engines/{id}', //delete 引擎：删除引擎
    downloadTestSet: '/api/cat/mt/models/{id}/test_sets/download', //get 模型：下载测试集
    setEngineStatus: '/api/cat/mt/engines/trans', //post 引擎：设置翻译状态
    queryTranslationResult: '/api/translator/translation/evaluation', //post  引擎：文本翻译
    queryModelStatus: '/api/cat/mt/models/loaded', //post 模型：获取是否加载
    queryEngineDetail: '/api/cat/mt/engines/detail', //get 引擎：详情信息 ?modelId=xx
    shareBuildAppendCorpus: '/api/cat/mt/corpus/appending', //post 审核：追加语料
    shareSetting: '/api/cat/mt/engines/{id}/share', //post 共享：开启或关闭

    //术语定制
    queryMyTerms: '/api/cat/mt/term/my', //get 我的术语
    createTerm: '/api/cat/mt/term/create', //post 添加术语
    updateTerm: '/api/cat/mt/term/update', //post 修改术语
    deleteTerm: '/api/cat/mt/term/delete', //post 删除术语

    queryUserConfig: '/api/system/user/config/mt', //get 引擎管理用户配置
    updateTermSwitch: '/api/system/user/config/mt/term-custom/switch', //get 设置术语定制开关
    
    queryShareInfo: '/api/cat/mt/engines/{id}/shareinfo', //get 获取引擎分享信息
    submitJoin: '/api/cat/mt/engines/{id}/join', // post 加入引擎协作者
    queryTargetTextHighlight: '/api/translator/translation/highlight', //post 译文高亮

    createGptEngineTask: '/api/cat/mt/engines/xgpt', // post 引擎：添加 XGPT 引擎
    getGptTaskStatus: '/api/cat/mt/models/{id}/status', // get 获取GPT引擎模型训练状态
    retrainGptEngine: '/api/cat/mt/engines/xgpt/retrain', //post 引擎：重新训练 XGPT 引擎
    queryCreateGptPermission: '/api/cat/mt/engines/xgpt/permissions', //get 查询是否可创建GPT引擎

    //翻译评分
    queryTranslatorConfigEngines: '/api/translator/engines/configuration_engines', //get 查询翻译引擎配置列表
    saveTranslatorConfigEngines: '/api/translator/common-engines/settings', //get 配置常用引擎

    //Rag引擎
    queryCreateRagPermission: '/api/cat/mt/engines/rag/permissions', //get RAG 权限相关
    createRagEngine: '/api/cat/mt/engines/rag', //post 引擎：创建 RAG 模型
    getRagTaskStatus: '/api/cat/mt/engines/rag/model/status', //get RAG 模型状态
    
};
const orgApi = {
    queryMyOrgList: '/api/system/organization/my', //get 我的组织列表
    submitApplyTrial: '/api/system/organization/apply/submit', //post 联系我们（申请企业版）
    transferOrg: '/api/system/organization/switchTo', // post 切换机构
    queryOrgInfo: '/api/system/organization/get', //get 获取企业信息
    updateOrgInfo: '/api/system/organization/update', //post 企业信息变更

    queryOrgMemberManagers: '/api/system/organization/member/managers', // 
    queryOrgMembers: '/api/system/organization/member/options-filter', //get 企业成员相关筛选

    queryOrgInfoNoToken: '/api/system/organization/permission-free/get', //get [无验证版本]企业详情
    
};
const teamApi = {
    addMember: '/api/system/team/member', //post 邀请(添加)团队成员

    queryInternalMemberList: '/api/system/internal/list', //post 成员列表（内部）
    queryMemberList: '/api/system/organization/member/list', //post 成员列表
    queryMemberDetail: '/api/system/organization/member/get', //get 获取团队成员详情
    updateMember: '/api/system/organization/member/update', //post 修改成员信息
    deleteMember: '/api/system/organization/member/delete', //post 删除成员
    transferRole: '/api/system/organization/member/transfer/role', //post 移交成员

    queryInviteCode: '/api/system/team/inviteCode', //post 获取或生成邀请码
    queryPreJoinUserInfo: '/api/system/team/apply/preJoin', //get 获取加入信息
    submitApplyJoin: '/api/system/team/apply', //post 提交加入申请
    queryApplyList: '/api/system/team/apply/list', //get 查看申请记录
    queryApplyDetail: '/api/system/team/apply/view/{id}', //get 查看申请详情
    queryRolesOptions: '/api/system/team/roles', //get 获取角色列表
    deletApplyMember: '/api/system/team/apply/{id}', //delete 删除申请记录
    acceptApply: '/api/system/team/apply/pass', //put 通过申请
    refuseApply: '/api/system/team/apply/deny', //put 拒绝申请

    queryTeamList: '/api/system/team/v2/query', //post 查询团队列表
    createTeam: '/api/system/team/v2/create', //post 创建团队
    updateTeam: '/api/system/team/v2/update', //post 修改团队信息
    deleteTeam: '/api/system/team/v2/remove', //get 删除团队

    queryMemberOfferList: '/api/system/team/quotation/list', //get 查询团队成员报价列表
    createMemberOffer: '/api/system/team/quotation', //post 修改团队成员报价
    updateMemberOffer: '/api/system/team/quotation', //put 修改团队成员报价
    deleteMemberOffer: '/api/system/team/quotation/{id}', //delete 删除团队成员报价
    batchImportQuotation: '/api/system/team/quotation/import', //post 批量导入报价

};

export {
    commonApi,
    projectApi,
    docApi,
    userApi,
    payApi,
    jargonApi,
    termApi,
    memoryApi,
    qaApi,
    findReplaceApi,
    toolsApi,
    myEngineApi,
    orgApi,
    teamApi,
    wsApi,
}
